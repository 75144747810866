
  import { defineComponent, ref, onMounted, computed } from "vue";
  import { useRoute, useRouter } from "vue-router";
  import { useStore } from "vuex";
  import useProducts from "@/composables/useProducts";
  import {
    IProductDto,
    CreateOrEditProductDto,
  } from "@/shared/service-proxies/generated-proxies";
  import Swal from "sweetalert2/dist/sweetalert2.min.js";
  import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";

  export default defineComponent({
    name: "CreateOrEditProduct",
    components: {},
    setup() {
      const {
        product,
        getProduct,
        updateOrCreateProduct,
        productUnitsLookUp,
        getProductUnitsForLookUp,
        productCategoriesLookUp,
        getProductCategoriesForLookUp,
      } = useProducts();
      const route = useRoute();
      const router = useRouter();
      const saving = ref(false);
      const productId = computed(() => route.query.id);
      const store = useStore();
      const breadcrumbs = {
        title: "Product Edit",
        breadcrumbs: ["Product", "Edit"],
      };

      const rules = ref({
        productCode: [
          {
            required: true,
            message: "Product code is required",
            trigger: "change",
          },
        ],
        productName: [
          {
            required: true,
            message: "Product name is required",
            trigger: "change",
          },
        ],
      });

      const defaultData = ref({
        displayOrder: 0,
        productCode: "",
        productName: "",
        shortName: "",
        unitPrice: "",
        imageURL: "",
        rcS8Code: "",
        productCategoryId: "",
        productBaseUnitId: "",
        isActive: false,
      }) as unknown as IProductDto;

      let formData = ref<IProductDto>(defaultData);

      const submit = async () => {
        saving.value = true;

        await updateOrCreateProduct(
          formData.value as unknown as CreateOrEditProductDto,
          undefined
        );

        const error = store.getters.getErrors;

        if (!error) {
          Swal.fire({
            text: productId.value
              ? "Product updated successfully"
              : "Product created successfully",
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "Okay!",
            customClass: {
              confirmButton: "btn fw-bold btn-light-success",
            },
          });
          router.push({ name: "products" });
        } else {
          Swal.fire({
            text: error,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Try again!",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        }
        saving.value = false;
      };

      onMounted(async () => {
        if (productId.value) {
          setCurrentPageBreadcrumbs("Edit Product", ["Product", "Edit"]);
        } else {
          setCurrentPageBreadcrumbs("Create Product", ["Product", "Create"]);
        }

        await getProductCategoriesForLookUp();
        await getProductUnitsForLookUp();

        if (productId.value) {
          await getProduct(productId.value as string);

          if (product.value) {
            formData.value = { ...product.value.product };
          }
        }
      });

      return {
        formData,
        saving,
        route,
        product,
        rules,
        productCategoriesLookUp,
        productUnitsLookUp,
        submit,
        breadcrumbs,
      };
    },
  });
